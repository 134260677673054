/* form */
.formField {
  margin: 30px;
}

.formField label {
  font-weight: bold;
}

.formField .seatInput {
  width: 50px;
}

.errorMsg {
  color: #ff7f50;
  font-weight: bold;
}

/* default */
.App {
  text-align: center;
}
