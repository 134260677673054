@import url("https://fonts.googleapis.com/css2?family=BIZ+UDPGothic&display=swap");

body {
  background-color: #fffaf0;
  margin: 0;
  font-family: "BIZ UDPGothic", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
